<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card elevation="2" :loading="bankAccountLoading" :disabled="bankAccountLoading">
      <v-card-title class="py-3 px-4"> Add Token </v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle class="py-2 px-3 text-h6 text-center">
        {{ ptopDetails.bank }} P2P
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="py-4 px-3">
        <div v-if="success">
          <v-alert text type="success" class="mt-4 mt-sm-6 mb-0">
            Successfully submitted! Your token will be added after our team approved your
            request.
          </v-alert>
          <!-- <v-btn
            block
            outlined
            class="mt-3"
            :to="{ name: 'AddPointsHistory' }"
          >
            Token History
          </v-btn> -->
          <div class="d-flex justify-end mt-4 mt-sm-6">
            <v-btn text class="light" @click="setDialogs('ptop')"> Close </v-btn>
          </div>
        </div>
        <v-form v-else ref="form" @submit.prevent="handleSubmit">
          <v-row no-gutters>
            <v-col cols="12" v-if="bankAccountLoading">
              <v-alert dense>
                <v-row justify="center" no-gutters>
                  <v-col cols="5">
                    <v-skeleton-loader type="text"></v-skeleton-loader>
                  </v-col>
                  <v-col cols="12">
                    <v-skeleton-loader type="text"></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="12" v-if="!bankAccountLoading && bankAccount">
              <v-alert dense class="orange lighten-5 orange--text mb-5 subtitle-1">
                <div class="text-center">
                  Send <span class="text-h6">100</span> to
                  <span class="text-h6">50,000</span>
                </div>
                <div class="text-center">
                  to <strong>{{ bankAccount.account_number }}</strong>
                  {{ bankAccount.account_name }}
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="points"
                :rules="[rules.required, minimumPoints]"
                label="Token to Add"
                placeholder="Enter token"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="reference_number"
                :rules="[rules.required]"
                label="Reference Number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="screen_shot"
                :rules="[rules.required]"
                accept=".png,.jpg"
                prepend-icon=""
                append-icon="mdi-camera"
                show-size
                :label="'Screenshot of the ' + ptopDetails.bank + ' receipt'"
                placeholder="Choose File"
                dense
                outlined
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="notes"
                label="Notes"
                outlined
                dense
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-btn
            block
            depressed
            color="primary"
            type="submit"
            :loading="addPointsLoading"
          >
            Submit
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
  props: {
    ptopDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    rules: formRules,
    points: "100",
    reference_number: "",
    screen_shot: null,
    notes: "",
    success: false,
  }),
  computed: {
    ...mapState(["userData"]),
    ...mapState("add_points", [
      "bankAccount",
      "bankAccountLoading",
      "addPointsError",
      "addPointsLoading",
    ]),
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.setDialogs("ptop");
      },
    },
  },
  mounted() {
    this.getBankAccount();
  },
  methods: {
    ...mapActions("add_points", ["doGetBankAccount", "doSubmit"]),
    setDialogs(selectedDialog) {
      this.$emit("setDialogs", selectedDialog);
    },
    async getBankAccount() {
      if (!this.bankAccountLoading) {
        this.$store.commit("add_points/setBankAccount", null);

        const formData = new FormData();
        formData.append("bank_id", this.ptopDetails.bank_id);

        const getData = new URLSearchParams(formData);

        await this.doGetBankAccount(getData);
      }
    },
    minimumPoints(val) {
      if (val >= 100 && val <= 10000) {
        return true;
      } else {
        return "Minimum of 100 and maximum of 10,000 only.";
      }
    },
    async handleSubmit() {
      if (this.$refs.form.validate() && !this.addPointsLoading) {
        const formData = new FormData();
        formData.append("bank_id", this.ptopDetails.bank_id);
        formData.append("bank_account_id", this.bankAccount.id);
        formData.append("account_number", this.userData.mobile_number);
        formData.append("reference_number", this.reference_number);
        formData.append("amount", this.points);
        formData.append("screen_shot", this.screen_shot);
        formData.append("notes", this.notes);

        await this.doSubmit(formData);

        if (!this.addPointsError) {
          this.success = true;
        }
      }
    },
  },
};
</script>
