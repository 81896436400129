<template>
  <div class="main-content pa-4 pa-sm-6 pa-md-8">
    <v-card class="elevation-10">
      <div class="pa-4 pa-sm-6">
        <h3 class="font-weight-bold">Add Token</h3>

        <div v-if="!bank_types_loading">
          <div v-if="pending_request < 3">
            <div class="grey--text mb-4 mb-sm-6 mt-2">Select an outlet</div>
            <v-sheet class="bs-bg-transparent">
              <v-btn-toggle class="d-block bs-bg-transparent outlet">
                <v-slide-group show-arrows>
                  <v-slide-item v-for="(item, i) in bank_types" :key="i">
                    <div class="d-flex justify-center align-center bs-overflow-hidden">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="setDialogs(item.bank.toLowerCase())"
                            v-bind="attrs"
                            v-on="on"
                            text
                            class="light me-3"
                          >
                            <v-img
                              :src="item.image"
                              alt="League"
                              max-width="32"
                              max-height="32"
                              class="rounded-circle"
                            >
                            </v-img>
                          </v-btn>
                        </template>
                        <span>
                          {{ item.bank }}
                        </span>
                      </v-tooltip>
                    </div>
                  </v-slide-item>

                  <!-- <v-slide-item>
                    <div class="d-flex justify-center align-center bs-overflow-hidden">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="setDialogs('gcash')"
                            v-bind="attrs"
                            v-on="on"
                            text
                            class="light me-3"
                          >
                            <v-img
                              src="@/assets/img/payments/gcash-logo.webp"
                              alt="League"
                              max-width="32"
                              max-height="32"
                              class="rounded-circle"
                            >
                            </v-img>
                          </v-btn>
                        </template>
                        <span>GCash</span>
                      </v-tooltip>
                    </div>
                  </v-slide-item>
                  <v-slide-item>
                    <div class="d-flex justify-center align-center bs-overflow-hidden">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="setDialogs('paymaya')"
                            v-bind="attrs"
                            v-on="on"
                            text
                            class="light me-3"
                          >
                            <v-img
                              src="@/assets/img/payments/maya-logo.webp"
                              alt="League"
                              max-width="32"
                              max-height="32"
                              class="rounded-circle"
                            >
                            </v-img>
                          </v-btn>
                        </template>
                        <span>Paymaya</span>
                      </v-tooltip>
                    </div>
                  </v-slide-item> -->
                </v-slide-group>
              </v-btn-toggle>
            </v-sheet>
          </div>
          <v-alert type="info" text v-else-if="pending_request >= 3" class="mb-0 mt-4 mt-sm-6">
            Maximum of 3 request. Please wait for the other request to be verified.
          </v-alert>
        </div>
        <div v-else>
          <div class="grey--text mb-4 mb-sm-6 mt-2">Select an outlet</div>
          <div class="d-flex bs-gap-2">
            <div class="rounded-lg overflow-hidden">
              <v-skeleton-loader type="image" width="64" height="64"></v-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="pa-4 pa-sm-6">
        <div class="d-flex justify-space-between bs-gap-3">
          <div class="d-flex flex-column">
            <div class="font-weight-bold">History</div>
            <div class="grey--text mt-2">Add token transaction history list</div>
          </div>
          <AddPointsHistoryFilterDIalog
            @filter_add_points_history="filter_add_points_history"
          />
        </div>
      </div>

      <v-divider></v-divider>

      <v-data-table
        :hide-default-footer="true"
        loading-text="Loading, please wait"
        :headers="headers"
        :items="add_points_history"
        :items-per-page="item_per_page_value"
        :loading="add_points_history_loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :page.sync="page"
        @page-count="page_count = $event"
      >
        <template v-slot:item.status="{ item }">
          <v-chip
            small
            :color="
              item.status == 'Valid'
                ? 'green white--text'
                : item.status == 'Invalid'
                ? 'red white--text'
                : 'blue white--text'
            "
          >
            {{ item.status }}
          </v-chip>
        </template>

        <template v-slot:item.points="{ item }">
          {{ money_format(parseFloat(item.points)) }}
        </template>

        <template v-slot:item.date="{ item }">
          <span>{{ date_format(item.date) }}</span>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="view_details(item)"
                class="d-none d-sm-inline"
              >
              <v-icon> mdi-card-search-outline </v-icon>
              </v-btn>
            </template>
            <span>View Details</span>
          </v-tooltip>

          <a
            href="#"
            @click.prevent="view_details(item)"
            class="d-inline d-sm-none"
          >
            View Details
          </a>
        </template>
      </v-data-table>

      <div v-if="add_points_history && add_points_history.length != 0">
        <v-divider></v-divider>

        <div class="d-flex align-center justify-space-between bs-gap-3 pa-4 pa-sm-6">
          <div class="d-flex align-center bs-gap-3">
            <v-select
              :value="item_per_page_value"
              :items="item_per_page"
              outlined
              dense
              label="Items"
              :hide-details="true"
              style="width: 100px"
              @change="item_per_page_value = parseInt($event, 10)"
            ></v-select>
            <div>
              Page
              {{
                page ? page : "0" + "-" + item_per_page_value ? item_per_page_value : "0"
              }}
              of {{ page_count ? page_count : "0" }}
            </div>
          </div>
          <v-pagination
            v-model="page"
            :length="page_count"
            :total-visible="0"
          ></v-pagination>
        </div>
      </div>
    </v-card>

    <v-dialog v-model="add_points_history_details_dialog" max-width="400">
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <div class="font-weight-bold mb-4 mb-sm-6">Add Token Details</div>

          <div class="d-flex flex-column bs-gap-2 mb-4 mb-sm-6">
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Status</span>
              <span
                class="text-end"
                :class="
                  add_points_history_details.status == 'Valid'
                    ? 'green--text'
                    : add_points_history_details.status == 'Invalid'
                    ? 'red--text'
                    : 'blue--text'
                "
                >{{ add_points_history_details.status }}</span
              >
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Token</span>
              <span class="text-end">
                {{ money_format(parseFloat(add_points_history_details.points)) }}
              </span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Bank</span>
              <span class="text-end">
                {{
                  add_points_history_details.bank ? add_points_history_details.bank : "-"
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Reference </span>
              <span class="text-end">
                {{
                  add_points_history_details.reference_number
                    ? add_points_history_details.reference_number
                    : "-"
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Link</span>
              <span class="text-end" v-if="!add_points_history_details.link"> - </span>
              <a
                :href="add_points_history_details.link"
                target="_blank"
                rel="noopener noreferrer"
                v-else
                class="text-end d-block"
                style="white-space: normal !important"
                >{{ limitString(add_points_history_details.link) }}</a
              >
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Requested Date</span>
              <span class="text-end">
                {{
                  add_points_history_details.date
                    ? date_format(add_points_history_details.date)
                    : "-"
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Evaluated Date</span>
              <span class="text-end">
                {{
                  add_points_history_details.evaluated_at
                    ? date_format(add_points_history_details.evaluated_at)
                    : "-"
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between bs-gap-4">
              <span class="grey--text">Remarks</span>
              <span class="text-end">
                {{
                  add_points_history_details.remarks
                    ? add_points_history_details.remarks
                    : "-"
                }}
              </span>
            </div>
          </div>

          <div class="d-flex justify-end">
            <v-btn
              @click="add_points_history_details_dialog = false"
              class="light"
              depressed
            >
              Close
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <PtopDialog v-if="ptopDialog" :ptopDetails="ptopDetails" @setDialogs="setDialogs" />
    <PaymayaDialog v-if="paymayaDialog" @setDialogs="setDialogs" />
    <GcashDialog v-if="gcashDialog" @setDialogs="setDialogs" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import responseGet from "@/helpers/api_request_get";
import formatDate from "@/helpers/formatDate";
import PtopDialog from "@/components/app/add_points/PtopDialog";
import PaymayaDialog from "@/components/app/add_points/PaymayaDialog";
import GcashDialog from "@/components/app/add_points/GcashDialog";
import AddPointsHistoryFilterDIalog from "@/components/app/add_points/AddPointsHistoryFilterDIalog";

export default {
  components: {
    PtopDialog,
    PaymayaDialog,
    GcashDialog,
    AddPointsHistoryFilterDIalog,
  },

  data: () => ({
    ptopDialog: false,
    paymayaDialog: false,
    gcashDialog: false,
    ptopDetails: "",
    dialogs: ["ptop", "paymaya", "gcash"],

    headers: [
      { text: "Token", value: "points", class: "text-uppercase" },
      { text: "Outlet", value: "bank", class: "text-uppercase" },
      { text: "Requested Date", value: "date", class: "text-uppercase" },
      { text: "Status", value: "status", class: "text-uppercase" },
      {
        text: "Action",
        value: "action",
        sortable: false,
        class: "text-uppercase",
        align: "right",
      },
    ],
    sortBy: "date",
    sortDesc: true,

    page: 1,
    page_count: 0,
    item_per_page: [5, 10, 20, 40, 80],
    item_per_page_value: 10,

    add_points_history: [],
    add_points_history_loading: false,
    add_points_history_details: [],
    add_points_history_details_dialog: false,

    pending_request: 0,

    filter_date_dialog: false,
    filter_date_start: "",
    filter_date_end: "",

    bank_types: [],
    bank_types_loading: false,
  }),

  mounted() {
    this.get_bank_types();
    this.get_add_points_history();

    this.$store.dispatch("add_points/getCashinAmounts");
  },

  computed: {
    ...mapState(["accountStatus"]),
    ...mapState("add_points", ["masterAgentType", "masterAgentTypeLoading"]),
  },

  methods: {
    ...mapActions("add_points", ["doGetMasterAgentType"]),

    async get_bank_types() {
      this.bank_types_loading = true;

      const form_data = new FormData();
      form_data.append("transaction_type", "for_add_token");

      const data = new URLSearchParams(form_data);

      const response = await responseGet(data, "bank_types");

      switch (response.status) {
        case 200:
          {
            this.bank_types = response.data.data;
          }
          break;
      }

      this.bank_types_loading = false;
    },

    async get_add_points_history() {
      this.add_points_history_loading = true;

      const form_data = new FormData();
      form_data.append("date_start", this.filter_date_start);
      form_data.append("date_end", this.filter_date_end);

      const data = new URLSearchParams(form_data);

      const response = await responseGet(data, "cashin/history");

      switch (response.status) {
        case 200:
          {
            this.add_points_history = response.data.records.history;
            this.pending_request = response.data.records.pending_count;
          }
          break;
      }

      this.add_points_history_loading = false;
    },

    async getMasterAgentType() {
      this.doGetMasterAgentType();
    },

    setDialogs(selectedDialog, ptoptype) {
      let newVal = !this[`${selectedDialog}Dialog`];
      this[`${selectedDialog}Dialog`] = newVal;

      if (ptoptype) {
        if (ptoptype == "gcash") {
          this.ptopDetails = {
            bank_id: 1,
            bank: "GCash",
          };
        }

        if (ptoptype == "paymaya") {
          this.ptopDetails = {
            bank_id: 2,
            bank: "Paymaya",
          };
        }
      }

      this.dialogs.forEach((dialog) => {
        if (dialog != selectedDialog) {
          this[`${dialog}Dialog`] = false;
        }
      });
    },

    date_format(theDate) {
      return formatDate(theDate);
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    view_details(item) {
      this.add_points_history_details = item;
      this.add_points_history_details_dialog = true;
    },

    date_filter() {
      this.$refs.filter_date_dialog.save(this.filter_date);
      this.get_add_points_history();
    },

    filter_add_points_history(data) {
      this.filter_date_start = data.date_start;
      this.filter_date_end = data.date_end;

      this.get_add_points_history();
    },

    limitString(string) {
      if (string) {
        return `${string.substring(0, 25)} ...`;
      }
    },
  },
};
</script>

<style scoped>
.outlet .v-btn {
  min-width: calc(32px + 32px) !important;
  height: calc(32px + 32px) !important;
}
</style>
